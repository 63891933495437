import React, { Component } from 'react';
import history from '../history';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spinner from "../components/Spinner";
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { PostData, baseUrl } from '../common';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            name: "",
            lname: "",
            email: "",
            mobile: "",
            message: "",
            errorMsg: "",
            loading: false
        };
    }

    componentDidMount() {

    }

    moveToContact = async () => {
        if (this.state.name === "") {
            this.setState({ errorMsg: "First name is required" });
        } else if (this.state.lname === "") {
            this.setState({ errorMsg: "Last name is required" });
        } else if (this.state.email === "") {
            this.setState({ errorMsg: "Email address is required" });
        } else if (this.state.mobile === "") {
            this.setState({ errorMsg: "Mobile number is required" });
        } else if (this.state.message === "") {
            this.setState({ errorMsg: "Message is required" });
        } else {
            this.setState({ loading: true });
            var params = new URLSearchParams();
            params.append('action', 'sendmail');
            params.append('name', this.state.name + " " + this.state.lname);
            params.append('email', this.state.email);
            params.append('telephone', this.state.mobile);
            params.append('comment', this.state.message);

            var self = this;
            this.setState({ errorMsg: "" });
            axios.post(baseUrl, params)
                .then(function (response) {
                    console.log(response);
                    self.setState({
                        loading: false,
                        errorMsg: response.data.message,
                        name: "",
                        lname: "",
                        mobile: "",
                        message: "",
                        email: ""
                    });

                    setTimeout(() => {
                        self.setState({ errorMsg: "" });
                    }, 4000);
                })
                .catch(error => {

                }).finally(() => {
                    self.setState({ loading: false });
                });
        }
    };

    render() {
        const { } = this.state;


        return (
            <>
                <section className="app-section">
                    <div className="main-body-section">
                        <Header />
                        <div className="m-register-banner">
                            <img src="ui/images/result-draw.jpg" alt="" title="" />
                            <span className="back-to-page">
                                <a href="#" onClick="window.history.go(-1)"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 492 420.22" style={{ enableBackground: 'new 0 0 492 420.22' }} xmlSpace="preserve">
                                    <g>
                                        <g>
                                            <path className="b-arrow" d="M0,210.12c-0.02,7.22,2.78,14.01,7.86,19.1L185.54,406.9c5.07,5.07,11.83,7.87,19.04,7.87
			c7.22,0,13.97-2.76,19.04-7.84l16.13-16.08c5.07-5.07,7.86-11.74,7.86-18.95c0-7.21-2.79-13.79-7.86-18.86L134.93,248.57h330.5
			c14.85,0,26.58-12.8,26.58-27.65v-22.81c0-14.85-11.73-26.48-26.58-26.48H136.1L239.76,67.75c5.07-5.06,7.86-12.06,7.86-19.26
			c0-7.21-2.79-13.98-7.86-19.04l-16.13-16.14c-5.07-5.07-11.83-7.86-19.04-7.86c-7.21,0-13.97,2.78-19.04,7.86L7.86,191
			C2.78,196.08-0.02,202.87,0,210.12z"/>
                                        </g>
                                    </g>
                                </svg></a>

                            </span>
                        </div>

                        <div className="_home_body_container">
                            <div className="m_boxs users_dashboard">
                                <div>
                                    <div className="js-pagination">
                                        <div data-position="0">
                                            <h1 className="head-text-seletected bar_line_seletected seletected-game-draw">Contact Support</h1>
                                            <div className="draw-data-box pages-text">
                                                {(this.state.errorMsg != "") && <strong>{this.state.errorMsg}</strong>}
                                                <div className="page-contact-grp">
                                                    <span>First Name</span>
                                                    <div className="page-contact-grp-input"><input type="text" name="" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} /></div>
                                                    <span>Last Name</span>
                                                    <div className="page-contact-grp-input"><input type="text" name="" value={this.state.lname} onChange={e => this.setState({ lname: e.target.value })} /></div>
                                                    <span>Email Address</span>
                                                    <div className="page-contact-grp-input"><input type="email" name="" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} /></div>
                                                    <span>Mobile Number</span>
                                                    <div className="page-contact-grp-input"><input type="tel" name="" value={this.state.mobile} onChange={e => this.setState({ mobile: e.target.value })} /></div>
                                                    <textarea id="w3review" name="" rows="4" cols="50" onChange={e => this.setState({ message: e.target.value })} value={this.state.message}></textarea>
                                                    {(this.state.loading == false) ?
                                                        <button className="v-button" value="Submit" onClick={() => this.moveToContact()}>Submit</button> :
                                                        <center><Spinner fill="#f9af30" bg="#fff" /></center>}
                                                </div>

                                                <div><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1891.4608786024612!2d-0.17522512732991385!3d5.567403100435055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9a9c6fcf2673%3A0xe41c85a9b4ca173!2sZen%20Garden!5e0!3m2!1sen!2sus!4v1645026648732!5m2!1sen!2sus" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe></div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </section>
                <div className="m_game_bet_slip">
                    <div className="slip-box">
                        <small>GH₵</small><span>20000</span>
                        <ul style={{ display: 'none' }}>
                            <li><a href="#">Funds Deposit</a></li>
                            <li><a href="#">Game History</a></li>
                            <li><a href="#">Withdraw Funds</a></li>
                            <li><a href="#">Wallet Transactions</a></li>
                            <li><a href="#">Update Profile</a></li>
                            <li><a href="#">Change Login Password Edit</a></li>
                        </ul>
                    </div>
                    <div className="m_user-profile"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 428.7 468.4" style={{ enableBackground: 'new 0 0 428.7 468.4' }} xmlSpace="preserve">
                        <g>
                            <g>
                                <g>
                                    <path className="user-icon" d="M214.4,3.8c-65.8,0-119.6,53.8-119.6,119.6s53.8,119.6,119.6,119.6S334,189.3,334,123.4S280.2,3.8,214.4,3.8
				z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="user-icon" d="M419.7,338.7c-3.1-7.8-7.3-15.2-12-21.9c-24-35.5-61.1-59-102.9-64.8c-5.2-0.5-11,0.5-15.2,3.7
				c-21.9,16.2-48.1,24.6-75.2,24.6s-53.3-8.4-75.2-24.6c-4.2-3.1-9.9-4.7-15.2-3.7c-41.8,5.7-79.4,29.3-102.9,64.8
				c-4.7,6.8-8.9,14.6-12,21.9c-1.6,3.1-1,6.8,0.5,9.9c4.2,7.3,9.4,14.6,14.1,20.9c7.3,9.9,15.2,18.8,24,27.2
				c7.3,7.3,15.7,14.1,24,20.9c41.3,30.8,90.9,47,142.1,47s100.8-16.2,142.1-47c8.4-6.3,16.7-13.6,24-20.9
				c8.4-8.4,16.7-17.2,24-27.2c5.2-6.8,9.9-13.6,14.1-20.9C420.7,345.5,421.2,341.8,419.7,338.7z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    </div>
                </div>
            </>
        );
    }
}
