import React from 'react';

export const baseUrl = "httpaccess.php";
// export const baseUrl = "https://213games.com.gh/httpaccess.php";

export const messages = {
    defaultError: {
        status: 99,
        message: 'Sorry we are not able to process this at this time, kindly retry',
    },
    loginError: {
        error: true,
        message: 'Sorry we are not able to process this at this time, kindly retry',
    },
};

export function formatDate(date) {
    if (date !== null) return date.substring(0, 25);
    else return '';
}

/**
 Get the baseUrl from the server
 */
export function getUserData() {
    let userData = localStorage.getItem('userD');
    let user = JSON.parse(userData);
    return user;
}

/**
 Check if the string is a json data
 */
export function isJSONValid(data) {
    try {
        let json = JSON.parse(data);
        return true;
    } catch (error) {
        return false;
    }
}
