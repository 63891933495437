import React, { Component } from 'react';
import history from '../history';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spinner from "../components/Spinner";
import { PostData, baseUrl } from '../common';
import axios from 'axios';
import qs from 'qs';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loading1: false,
            recovery_number: "",
            errorMessage: "",
            errorMsg1: "",
            loading: false,
            reset: 0,
            user: {},
            payload: {},
            prevEmail: "",
            confirmation: false,
            tokens: ["", "", "", "", "", ""],
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        var userD = await localStorage.getItem("userD");
        if (userD !== null) {
            userD = JSON.parse(userD);
            if (userD.user != undefined) {
                var email = "";
                if (userD.user.emails != undefined)
                    if (userD.user.emails[0] != undefined)
                        if (userD.user.emails[0].value != undefined) {
                            email = userD.user.emails[0].value;
                            this.setState({ prevEmail: email });
                        }

                this.setState({
                    isLoggedIn: true, user: userD,
                    payload: {
                        firstName: userD.user.firstName,
                        lastName: userD.user.lastName,
                        mobileNumber: userD.user.mobileNumber.value,
                        operator: userD.user.mobileNumber.operator,
                        email: email
                    }
                });
            }
        }

        this.setState({ loading: false });
    }

    onChange = async (e) => {
        var payload = this.state.payload;
        payload[e.target.name] = e.target.value;
        this.setState({ payload: payload });
    }

    updateProfile = async () => {
        var payload = this.state.payload;
        if (payload.firstName == "") {
            this.setState({ errorMessage: "First name is required" })
        } else if (payload.lastName == "") {
            this.setState({ errorMessage: "Last name is required" })
        } else {
            this.setState({ loading: true });
            var params = qs.stringify({
                'action': 'updateAccount',
                'firstName': this.state.payload.firstName,
                'lastName': this.state.payload.lastName,
                'operator': this.state.payload.operator,
                'token': this.state.user.token
            });

            var self = this;
            this.setState({ loading: true });

            //Call for email update 
            if (this.state.payload.email != "")
                this.updateEmail();

            axios.post(baseUrl, params)
                .then(function (response) {
                    if (response.data.status == 4) {
                        history.push('/logout');
                        window.location.href = window.location.href;
                    } else {
                        self.setState({ errorMsg: response.data.message });
                        self.setState({ loading: false });
                        if (response.data.status == 1)
                            self.refreshData();
                    }
                })
                .catch(error => {
                    self.setState({ loading: false, errorMsg: "Sorry unable to proceed your request at this time." });
                });
        }
    }

    updateEmail = async () => {
        console.log(this.state.prevEmail);
        console.log(this.state.payload.email);
        if (this.state.payload.email !== this.state.prevEmail) {
            var params = qs.stringify({
                'action': 'updateemail',
                'email': this.state.payload.email,
                'token': this.state.user.token
            });

            var self = this;
            this.setState({ loading: true });
            axios.post(baseUrl, params)
                .then(function (response) {
                    console.log(response.data.message);
                    self.setState({ confirmation: true });
                    self.activation();
                })
                .catch(error => {
                    self.setState({ loading: false, errorMsg: "Sorry unable to proceed your request at this time." });
                });
        }
    }

    refreshData = async () => {
        var params = qs.stringify({
            'action': 'refreshdata',
            'token': this.state.user.token
        });

        var self = this;
        this.setState({ loading: true });
        axios.post(baseUrl, params)
            .then(function (response) {
                console.log(response.data.user);
                var getCurrentData = self.state.user;

                getCurrentData.user = response.data.user;
                localStorage.setItem("userD", JSON.stringify(getCurrentData));
                self.loadData();
            })
            .catch(error => {
                self.setState({ loading: false, errorMsg: "Sorry unable to proceed your request at this time." });
            });
    }

    resetPassword = async () => {
        var params = qs.stringify({
            'action': 'inapppasswordreset',
            'oldPassword': this.state.payload.oldpassword,
            'newPassword': this.state.payload.newpassword,
            'confirmPassword': this.state.payload.confirmpassword,
            token: this.state.user.token
        });

        var self = this;
        this.setState({ loading1: true });
        axios.post(baseUrl, params)
            .then(function (response) {
                console.log(response.data.message);
                self.setState({ loading1: false, errorMsg1: response.data.message });
            })
            .catch(error => {
                self.setState({ loading1: false, errorMsg1: "Sorry unable to proceed your request at this time." });
            });
    }

    activation = async () => {
        var params = qs.stringify({
            'action': 'verifyemail',
            'token': this.state.user.token
        });

        var self = this;
        this.setState({ loading: true });
        axios.post(baseUrl, params)
            .then(function (response) {

            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        const { payload, errorMsg, errorMsg1 } = this.state;

        return (
            <>
                <section className="app-section">
                    <div className="main-body-section">
                        <Header />
                        <div className="m-register-banner">
                            <img src="ui/images/reset.jpg" alt="" title="" />
                            <span className="back-to-page">
                                <a href="#" onClick="window.history.go(-1)"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 492 420.22" style={{ enableBackground: 'new 0 0 492 420.22' }} xmlSpace="preserve">
                                    <g>
                                        <g>
                                            <path className="b-arrow" d="M0,210.12c-0.02,7.22,2.78,14.01,7.86,19.1L185.54,406.9c5.07,5.07,11.83,7.87,19.04,7.87
			c7.22,0,13.97-2.76,19.04-7.84l16.13-16.08c5.07-5.07,7.86-11.74,7.86-18.95c0-7.21-2.79-13.79-7.86-18.86L134.93,248.57h330.5
			c14.85,0,26.58-12.8,26.58-27.65v-22.81c0-14.85-11.73-26.48-26.58-26.48H136.1L239.76,67.75c5.07-5.06,7.86-12.06,7.86-19.26
			c0-7.21-2.79-13.98-7.86-19.04l-16.13-16.14c-5.07-5.07-11.83-7.86-19.04-7.86c-7.21,0-13.97,2.78-19.04,7.86L7.86,191
			C2.78,196.08-0.02,202.87,0,210.12z"/>
                                        </g>
                                    </g>
                                </svg></a>

                            </span>
                        </div>
                        <div className="_home_body_container">
                            <div className="m_boxs users_dashboard">
                                <div>
                                    <div className="js-pagination">
                                        <div data-position="0" style={{ display: 'block' }}>
                                            <h1 className="bar_line_seletected">Basic Information</h1>
                                            {(errorMsg != "") && <div><small><strong style={{ color: '#f00' }}>{errorMsg}</strong></small><br /></div>}

                                            <span>Phone Number</span><div className="kc-input input-ctrl disable-box">
                                                <input type="tel" placeholder="245***895" className="disable" disabled value={payload['mobileNumber']} className="" name="mobileNumber" onChange={(e) => this.onChange(e)} />
                                            </div>

                                            <span>First Name</span><div className="kc-input input-ctrl"><input type="name" placeholder="" value={payload['firstName']} className="" name="firstName" onChange={(e) => this.onChange(e)} />
                                            </div>
                                            <span>Last Name</span><div className="kc-input input-ctrl"><input type="name" placeholder="" value={payload['lastName']} className="" name="lastName" onChange={(e) => this.onChange(e)} className="" />
                                            </div>

                                            <span>Email Address</span><div className="kc-input input-ctrl"><input type="name" placeholder="" value={payload['email']} className="" name="email" onChange={(e) => this.onChange(e)} className="" />
                                            </div>

                                            <div className="misdn">
                                                <span>Select Mobile Network</span>
                                                <label className="check-reset">
                                                    <img src="ui/images/mnos-mtn.png" style={{ width: '10%' }} />
                                                    <input type="radio" name="operator" value="mtn-gh" onClick={() => { var p = payload; p['operator'] = "mtn"; this.setState({ payload: p }) }} checked={(payload['operator'] == "mtn" ? true : false)} />
                                                    <span className="checkmark" style={{ backgroundColor: '#202341' }}></span>
                                                </label>
                                                <label className="check-reset">
                                                    <img src="ui/images/mnos-airtel.png" style={{ width: '10%' }} />
                                                    <input type="radio" name="operator" value="tigo-gh" onClick={() => { var p = payload; p['operator'] = "AIRTELTIGO"; this.setState({ payload: p }) }} checked={(payload['operator'] == "AIRTELTIGO" ? true : false)} />
                                                    <span className="checkmark" style={{ backgroundColor: '#202341' }}></span>
                                                </label>
                                                <label className="check-reset">
                                                    <img src="ui/images/mnos-voda.png" style={{ width: '10%' }} />
                                                    <input type="radio" name="operator" value="vodafone-gh-ussd" onClick={() => { var p = payload; p['operator'] = "vodafone"; this.setState({ payload: p }) }} checked={(payload['operator'] == "vodafone" ? true : false)} />
                                                    <span className="checkmark" style={{ backgroundColor: '#202341' }}></span>
                                                </label>
                                            </div>

                                            {(this.state.loading == false) ?
                                                <button className="v-button" value="Submit" onClick={() => this.updateProfile()}>Save</button> : <center><Spinner fill="#202341" bg="#fff" /></center>}
                                        </div>


                                        {/* <div data-position="0" style={{ display: 'none' }} className="account_security">
                                            <h1 className="bar_line_seletected">Account Security</h1>

                                            {(errorMsg1 != "") && <div><small><strong style={{ color: '#f00' }}>{errorMsg1}</strong></small><br /></div>}

                                            <span>Old Password
                                            </span><div className="kc-input input-ctrl">
                                                <input type="password" placeholder="Old Password" className="" name="oldpassword" onChange={(e) => this.onChange(e)} />
                                            </div>
                                            <span>Enter New Password</span><div className="kc-input input-ctrl">
                                                <input type="password" placeholder="Enter New Password" className="" name="newpassword" onChange={(e) => this.onChange(e)} />
                                            </div>

                                            <span>Confirm Password</span><div className="kc-input input-ctrl">
                                                <input type="password" placeholder="Confirm Password" className="" name="confirmpassword" onChange={(e) => this.onChange(e)} />
                                            </div>
                                            {(this.state.loading1 == false) ?
                                                <button className="v-button" value="Submit" onClick={() => this.resetPassword()}>Reset</button> : <center><Spinner fill="#202341" bg="#fff" /></center>}
                                        </div> */}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <Footer />
                </section>
                <div className="m_game_bet_slip">
                    <div className="slip-box">
                        <small>GH₵</small><span>20000</span>
                        <ul style={{ display: 'none' }}>
                            <li><a href="#">Funds Deposit</a></li>
                            <li><a href="#">Game History</a></li>
                            <li><a href="#">Withdraw Funds</a></li>
                            <li><a href="#">Wallet Transactions</a></li>
                            <li><a href="#">Update Profile</a></li>
                            <li><a href="#">Change Login Password Edit</a></li>
                        </ul>
                    </div>
                    <div className="m_user-profile"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 428.7 468.4" style={{ enableBackground: 'new 0 0 428.7 468.4' }} xmlSpace="preserve">
                        <g>
                            <g>
                                <g>
                                    <path className="user-icon" d="M214.4,3.8c-65.8,0-119.6,53.8-119.6,119.6s53.8,119.6,119.6,119.6S334,189.3,334,123.4S280.2,3.8,214.4,3.8
				z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="user-icon" d="M419.7,338.7c-3.1-7.8-7.3-15.2-12-21.9c-24-35.5-61.1-59-102.9-64.8c-5.2-0.5-11,0.5-15.2,3.7
				c-21.9,16.2-48.1,24.6-75.2,24.6s-53.3-8.4-75.2-24.6c-4.2-3.1-9.9-4.7-15.2-3.7c-41.8,5.7-79.4,29.3-102.9,64.8
				c-4.7,6.8-8.9,14.6-12,21.9c-1.6,3.1-1,6.8,0.5,9.9c4.2,7.3,9.4,14.6,14.1,20.9c7.3,9.9,15.2,18.8,24,27.2
				c7.3,7.3,15.7,14.1,24,20.9c41.3,30.8,90.9,47,142.1,47s100.8-16.2,142.1-47c8.4-6.3,16.7-13.6,24-20.9
				c8.4-8.4,16.7-17.2,24-27.2c5.2-6.8,9.9-13.6,14.1-20.9C420.7,345.5,421.2,341.8,419.7,338.7z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    </div>
                </div>
            </>
        );
    }
}
