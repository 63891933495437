import React, { Component } from 'react';
import history from '../history';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spinner from "../components/Spinner";
import queryString from 'query-string';
import { PostData, baseUrl } from '../common';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            id: "",
            label: "",
            dataset: [],
            page: -1,
            filter: "",
            disableFilter: true
        };
    }

    componentDidMount() {
        this.loadData(1);

        var x, i, j, l, ll, selElmnt, a, b, c;
        /*look for any elements with the class "custom-select":*/
        x = document.getElementsByClassName("custom-select");
        l = x.length;
        for (i = 0; i < l; i++) {
            selElmnt = x[i].getElementsByTagName("select")[0];
            ll = selElmnt.length;
            /*for each element, create a new DIV that will act as the selected item:*/
            a = document.createElement("DIV");
            a.setAttribute("class", "select-selected");
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
            x[i].appendChild(a);
            /*for each element, create a new DIV that will contain the option list:*/
            b = document.createElement("DIV");
            b.setAttribute("class", "select-items select-hide");
            for (j = 1; j < ll; j++) {
                /*for each option in the original select element,
                create a new DIV that will act as an option item:*/
                c = document.createElement("DIV");
                c.innerHTML = selElmnt.options[j].innerHTML;
                c.addEventListener("click", function (e) {
                    /*when an item is clicked, update the original select box,
                    and the selected item:*/

                    var y, i, k, s, h, sl, yl;
                    s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                    sl = s.length;

                    h = this.parentNode.previousSibling;

                    console.log(h, this.innerHTML);
                    localStorage.setItem("results", this.innerHTML);
                    window.location = "/results";
                    for (i = 0; i < sl; i++) {
                        if (s.options[i].innerHTML == this.innerHTML) {
                            s.selectedIndex = i;
                            h.innerHTML = this.innerHTML;
                            y = this.parentNode.getElementsByClassName("same-as-selected");
                            yl = y.length;
                            for (k = 0; k < yl; k++) {
                                y[k].removeAttribute("class");
                            }
                            this.setAttribute("class", "same-as-selected");
                            break;
                        }
                    }
                    h.click();
                });
                b.appendChild(c);
            }
            x[i].appendChild(b);
            a.addEventListener("click", function (e) {
                /*when the select box is clicked, close any other select boxes,
                and open/close the current select box:*/
                e.stopPropagation();
                closeAllSelect(this);
                this.nextSibling.classList.toggle("select-hide");
                this.classList.toggle("select-arrow-active");
            });
        }
        function closeAllSelect(elmnt) {
            /*a function that will close all select boxes in the document,
            except the current select box:*/
            var x, y, i, xl, yl, arrNo = [];
            x = document.getElementsByClassName("select-items");
            y = document.getElementsByClassName("select-selected");
            xl = x.length;
            yl = y.length;
            for (i = 0; i < yl; i++) {
                if (elmnt == y[i]) {
                    arrNo.push(i)
                } else {
                    y[i].classList.remove("select-arrow-active");
                }
            }
            for (i = 0; i < xl; i++) {
                if (arrNo.indexOf(i)) {
                    x[i].classList.add("select-hide");
                }
            }
        }
        /*if the user clicks anywhere outside the select box,
        then close all select boxes:*/
        document.addEventListener("click", closeAllSelect);
    }

    loadData = async (counter) => {
        let id = await localStorage.getItem("results");

        if (id == null) {
            this.setState({ id: "jumbo1", label: "Jumbo 1" })
        } else if (id == "Jumbo 1") {
            this.setState({ id: "jumbo1", label: "Jumbo 1" })
        } 
        // else if (id == "Jumbo 2") {
        //     this.setState({ id: "jumbo2", label: "Jumbo 2" })
        // } 
        else if (id == "Magic 3") {
            this.setState({ id: "magic3", label: "Magic 3" })
        } else if (id == "Magic 5") {
            this.setState({ id: "magic5", label: "Magic 5" })
        } else if (id == "Mega 6") {
            this.setState({ id: "mega6", label: "Mega 6" })
        } 
        // else if (id.startsWith("Cash")) {
        //     const number = id.replace("Cash ", "");
        //     this.setState({ id: `cash${number}`, label: `Cash ${number}` })
        // } 
        else if (id == "Mega 7") {
            this.setState({ id: "mega7", label: "Mega 7" })
        }

        //Fetch the 

        let page = Number(this.state.page);
        if (counter == 0) {
            page = page - 1;
            console.log("page::", page);
            this.setState({ page: page });
        } else {
            page = page + 1;
            this.setState({ page: page });
        }

        var params = {
            'action': 'mxresult',
            'index': page
        };

        if (id == "Jumbo 2") {
            params.action = "pxapiresult";
            params.picks = 2;
        } else if (id == "Magic 3") {
            params.action = "pxresult";
        } else if (id == "Magic 5") {
            params.action = "pxapiresult";
            params.picks = 5;
        } else if (id == "Mega 6") {
            params.action = "pxapiresult";
            params.picks = 6;
        } else if (id == "Mega 7") {
            params.action = "pxapiresult";
            params.picks = 7;
        } else if (id && id.startsWith("Cash")) {
            params.action = "mxresult";
            params.picks = id.replace("Cash ", "");
        } else {
            params.action = "pxapiresult";
            params.picks = 1;
        }
        params.max = 10;
        params.isComplete = true;

        var self = this;
        this.setState({ loading: true });

        axios.post(baseUrl, qs.stringify(params))
            .then(function (response) {
                if (response.data.status == 4) {
                    history.push('/logout');
                    window.location.href = window.location.href;
                } else {
                    self.setState({ errorMsg: response.data.message });
                    self.setState({ loading: false });
                    if (response.data.status == 1 && response.data.draws.length > 0) {
                        self.setState({ dataset: response.data.draws });
                        console.log(response.data.draws.length);
                        console.log("added");
                        // const page = Number(self.state.page);
                        // if (counter == 1)
                        //     self.setState({ page: page+1 });
                        if (counter === 0)
                            self.setState({ disableFilter: true });
                    } else {
                        console.log("here::", counter);
                        if (counter == 0 && page > 0) {
                            self.setState({ page: page + 1 });
                        } else {
                            self.setState({ page: page - 1, disableFilter: false });
                        }
                    }
                }
            })
            .catch(error => {
                // var page = Number(self.state.page);
                // if (counter == 0)
                //     page = page + 1;
                // self.setState({ page: page });
                // console.log("here-- error");
            }).finally(() => {
                self.setState({ loading: false });
            });
    };

    render() {
        const { filter, label, dataset, page } = this.state;
        return (
            <>
                <section className="app-section">
                    <div className="main-body-section">
                        <Header />
                        <div className="m-register-banner">
                            <img src="ui/images/result-draw.jpg" alt="" title="" />
                            <span className="back-to-page">
                                <a href="#" onClick="window.history.go(-1)"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 492 420.22" style={{ enableBackground: 'new 0 0 492 420.22' }} xmlSpace="preserve">
                                    <g>
                                        <g>
                                            <path className="b-arrow" d="M0,210.12c-0.02,7.22,2.78,14.01,7.86,19.1L185.54,406.9c5.07,5.07,11.83,7.87,19.04,7.87
			c7.22,0,13.97-2.76,19.04-7.84l16.13-16.08c5.07-5.07,7.86-11.74,7.86-18.95c0-7.21-2.79-13.79-7.86-18.86L134.93,248.57h330.5
			c14.85,0,26.58-12.8,26.58-27.65v-22.81c0-14.85-11.73-26.48-26.58-26.48H136.1L239.76,67.75c5.07-5.06,7.86-12.06,7.86-19.26
			c0-7.21-2.79-13.98-7.86-19.04l-16.13-16.14c-5.07-5.07-11.83-7.86-19.04-7.86c-7.21,0-13.97,2.78-19.04,7.86L7.86,191
			C2.78,196.08-0.02,202.87,0,210.12z"/>
                                        </g>
                                    </g>
                                </svg></a>

                            </span>
                        </div>


                        <div className="_home_body_container">
                            <div className="m_boxs users_dashboard">

                                <div>
                                    <div className="js-pagination">
                                        <div data-position="0" style={{ display: 'block', position: 'relative' }}>
                                            <h1 className="head-text-seletected bar_line_seletected seletected-game-draw">Draw Results - <span>{label}</span></h1>
                                            <div className="drop-games">
                                                <div className="custom-select" style={{ width: '160px' }}>
                                                    <select
                                                    // onChange={(e) => {
                                                    //     history.push('/results?id=' + e.target.value);
                                                    //     window.location = window.location.href
                                                    // }}
                                                    >
                                                        <option disabled selected>Select Game:</option>
                                                        <option value="jumbo1">Jumbo 1</option>
                                                        {/* <option value="jumbo2">Jumbo 2</option> */}
                                                        <option value="mega3">Magic 3</option>
                                                        <option value="mega4">Magic 5</option>
                                                        <option value="mega5">Mega 6</option>
                                                        <option value="mega6">Mega 7</option>
                                                        {/* <option value="cash1">Cash Games</option> */}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="search-draw-input">
                                                <input type="text" name="search-draw" placeholder="Search Draw by ID" onChange={(e) => this.setState({ filter: e.target.value })} />
                                            </div>

                                            {filter}
                                            <div className="draw-data-box">
                                                {(!filter ? (dataset || []) : (dataset || [])?.filter(f => JSON.stringify(f).includes(filter)))?.map(draw => {
                                                    return (<div className="m-market">
                                                        <div className="table-body"><h5>{label}</h5>
                                                            <div className="td-row"><h6>ID</h6>{draw.id}</div>
                                                            <div className="td-row"><h6>Date</h6> {draw.date}</div>
                                                            <div className="td-row double-count"><h6 className="hr-left-margin">Draw</h6> {(draw.winningNumbers?.split(",") || []).map(m => <span>{m}</span>)}</div>
                                                        </div>
                                                    </div>);
                                                })}
                                            </div>
                                            {(this.state.loading == true) && <center><Spinner fill="#202341" bg="#fff" /></center>}
                                            {(this.state.loading == false && this.state.disableFilter === true) && <a style={{ cursor: "pointer" }} id="loadMore" onClick={() => this.loadData(1)}>Load More<i className="fa fa-arrow-down"></i></a>}

                                            {page > 0 &&
                                                <div className="results-to-back" style={{ marginTop: 10 }}><a style={{ cursor: "pointer" }} onClick={() => this.loadData(0)}><i className="fa fa-arrow-left"></i>Back</a></div>
                                            }
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </section>
                <div className="m_game_bet_slip">
                    <div className="slip-box">
                        <small>GH₵</small><span>20000</span>
                        <ul style={{ display: 'none' }}>
                            <li><a href="#">Funds Deposit</a></li>
                            <li><a href="#">Game History</a></li>
                            <li><a href="#">Withdraw Funds</a></li>
                            <li><a href="#">Wallet Transactions</a></li>
                            <li><a href="#">Update Profile</a></li>
                            <li><a href="#">Change Login Password Edit</a></li>
                        </ul>
                    </div>
                    <div className="m_user-profile"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 428.7 468.4" style={{ enableBackground: 'new 0 0 428.7 468.4' }} xmlSpace="preserve">
                        <g>
                            <g>
                                <g>
                                    <path className="user-icon" d="M214.4,3.8c-65.8,0-119.6,53.8-119.6,119.6s53.8,119.6,119.6,119.6S334,189.3,334,123.4S280.2,3.8,214.4,3.8
				z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="user-icon" d="M419.7,338.7c-3.1-7.8-7.3-15.2-12-21.9c-24-35.5-61.1-59-102.9-64.8c-5.2-0.5-11,0.5-15.2,3.7
				c-21.9,16.2-48.1,24.6-75.2,24.6s-53.3-8.4-75.2-24.6c-4.2-3.1-9.9-4.7-15.2-3.7c-41.8,5.7-79.4,29.3-102.9,64.8
				c-4.7,6.8-8.9,14.6-12,21.9c-1.6,3.1-1,6.8,0.5,9.9c4.2,7.3,9.4,14.6,14.1,20.9c7.3,9.9,15.2,18.8,24,27.2
				c7.3,7.3,15.7,14.1,24,20.9c41.3,30.8,90.9,47,142.1,47s100.8-16.2,142.1-47c8.4-6.3,16.7-13.6,24-20.9
				c8.4-8.4,16.7-17.2,24-27.2c5.2-6.8,9.9-13.6,14.1-20.9C420.7,345.5,421.2,341.8,419.7,338.7z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    </div>
                </div>
            </>
        );
    }
}
