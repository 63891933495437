import React, { Component } from 'react';
import history from '../history';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spinner from "../components/Spinner";
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { PostData, baseUrl } from '../common';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            input: {},
            numbers: 1,
            minStake: 0,
            mobile: "",
            maxStake: 0,
            isLoggedIn: false,
            playing: false,
            user: {},
            operator: "mtn",
            stake: "",
            errorMsg: "",
            limit: 6,
            latest: []
        };
    }

    componentDidMount() {
        this.loadData();
        this.latestResults();
    }

    loadData = async () => {
        var userD = await localStorage.getItem("userD");
        if (userD !== null) {
            userD = JSON.parse(userD);
            if (userD.user != undefined) {
                var mobile = userD.user.mobileNumber.value;
                this.setState({ mobile: mobile, isLoggedIn: true, user: userD, operator: userD.user.mobileNumber.operator });
            }
        }

        this.getGameDetails(1);
    };

    initGame = async (game) => {
        const number = game > 7 ? game - 7 : game;
        console.log(number)
        this.setState({
            input: new Array(number).fill(""),
            numbers: number,
            stake: "",
            game: game
        });
        this.getGameDetails(game);
    };

    latestResults = async () => {
        //, 8,2,
        var pricesList = [1, 3, 5, 6, 7];
        var output = [];
        var self = this;
        Promise.all(
            pricesList.map(vals => {
                return new Promise((resolve) => {
                    var params = {
                        'action': (vals == 3) ? 'pxresult' : vals === 8 ? 'mxresult' : 'pxapiresult',
                        'index': 0,
                        picks: vals,
                        max: 1,
                        isComplete: true
                    };
                    axios.post(baseUrl, qs.stringify(params))
                        .then(function (response) {
                            if (response.data.status == 4) { } else {
                                if (response.data.draws != undefined)
                                    resolve({ index: vals, draws: response.data.draws });
                                else
                                    resolve({ index: vals, draws: [] });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
            })
        ).then(function (values) {
            self.setState({ latest: values });
        });
    }

    getGameDetails = async (number) => {

        var params = {
            'action': 'mxresult',
            'index': 0
        };
        if (number == 2) {
            params.action = "pxapiresult";
            params.picks = number;
        } else if (number == 3) {
            params.action = "pxresult";
        } else if (number == 5) {
            params.action = "pxapiresult";
            params.picks = number;
        } else if (number == 6) {
            params.action = "pxapiresult";
            params.picks = number;
        } else if (number == 7) {
            params.action = "pxapiresult";
            params.picks = number;
        } 
        // else if (number > 7 && number < 12) {
        //     params.action = "mxresult";
        //     params.picks = number - 6;
        // } 
        else {
            params.action = "pxapiresult";
            params.picks = 1;
        }
        params.max = 1;
        params.isComplete = false;

        var self = this;
        this.setState({ loading: true });

        axios.post(baseUrl, qs.stringify(params))
            .then(function (response) {
                self.setState({
                    maxStake: response.data.draw.maxStake,
                    minStake: response.data.draw.minStake,
                    limit: response?.data?.maxNumber || 9,
                    minNumber: response?.data?.minNumber || 0
                });
            })
            .catch(error => {
                self.setState({ loading: false });
            });
    }

    playGame = async (number) => {
        var userD = await localStorage.getItem("userD");
        if (userD === null) {
            this.setState({ errorMsg: "You have to be loggedIn to play" });
            setTimeout(function () {
                history.push("/")
                window.location = window.location.href;
            }, 2000);

        } else {
            var validation = true;
            var nums = [];
            console.log(this.state.input);
            for (var d in this.state.input) {
                if (this.state.input[d] == "") {
                    validation = false;
                } else {
                    nums.push(this.state.input[d]);
                }
            }

            if (validation == false) {
                this.setState({ errorMsg: "Kindly fill all number slots" });
            } else if (Number(this.state.stake) < Number(this.state.minStake)) {
                this.setState({ errorMsg: "You cannot stake below GHS" + this.state.minStake });
            } else if (Number(this.state.stake) > Number(this.state.maxStake)) {
                this.setState({ errorMsg: "You cannot stake above GHS" + this.state.maxStake });
            } else {
                var payload = {}
                this.setState({ loading: true });
                console.log(this.state.user);
                if (this.state.numbers == 3 && this.state.game === 3) {
                    payload = {
                        "token": this.state.user.token,
                        "stake": Number(this.state.stake),
                        "numbers": nums.join(","),
                        "currency": "GHS",
                        "action": "pxplay",
                        "numberOfPicks": this.state.numbers,
                        "mobileNumber": this.state.mobile,
                        "operator": this.state.operator
                    }
                } else if (this.state.numbers == 4 || this.state.game > 7) {
                    const picks = this.state.game > 7 ? this.state.game - 7 : this.state.game;
                    payload = {
                        "token": this.state.user.token,
                        "stake": Number(this.state.stake),
                        "numbers": nums.join(","),
                        "currency": "GHS",
                        "action": "mxplay",
                        "numberOfPicks": picks,
                        "mobileNumber": this.state.mobile,
                        "operator": this.state.operator
                    }
                } else {
                    payload = {
                        "token": this.state.user.token,
                        "stake": Number(this.state.stake),
                        "numbers": nums.join(","),
                        "currency": "GHS",
                        "action": "pickxPlay",
                        "numberOfPicks": this.state.numbers,
                        "mobileNumber": this.state.mobile,
                        "operator": this.state.operator
                    }
                }

                if (this.state.game > 7)
                    payload.isCombination = false;

                var params = qs.stringify(payload);

                var self = this;
                this.setState({ playing: true });
                axios.post(baseUrl, params)
                    .then(function (response) {
                        console.log(response);
                        if (response.data.status == 4) {
                            history.push("logout");
                            window.location = window.location.href;
                        }
                        self.setState({ errorMsg: response.data.message });
                        self.setState({ playing: false, stake: "" });
                        setTimeout(() => {
                            self.setState({ input: {} });
                        }, 4000);

                    })
                    .catch(error => {
                        console.log(error);
                        self.setState({ errorMsg: "Sorry unable to process your request at this time" });
                        self.setState({ playing: false });
                    }).finally(() => {
                        setTimeout(() => {
                            self.setState({ errorMsg: "" });
                        }, 4000)
                    });
            }
        }
    }

    playInput = async (event) => {
        var limit = Number(this.state.limit);
        var input = this.state.input;

        if (Number(event.target.value) <= limit) {
            if (limit < 10 && event.target.value.length == 1) {
                console.log("here");
                input[event.target.name] = event.target.value;
                this.setState({ input: input });
            } else if (limit > 10) {
                input[event.target.name] = event.target.value;
                this.setState({ input: input });
            }
        }

        if (event.target.value.length == 0) {

            input[event.target.name] = "";
            this.setState({ input: input });
        }
    }

    render() {
        const { latest } = this.state;

        var btn_ = <a href="#popup2" className="submit_btn" style={{ textDecoration: 'none' }}>Play Now</a>;
        if (this.state.isLoggedIn == true) {
            btn_ = <input type="button" value="Play Now" className="submit_btn" onClick={() => this.playGame()} />;
            if (this.state.playing == true) {
                btn_ = <center><Spinner fill="#fff" bg="#f9af30" /></center>;
            } else {
                btn_ = <input type="button" value="Play Now" className="submit_btn" onClick={() => this.playGame()} />;
            }
        }

        let inputs = [];
        for (var i = 0; i < this.state.numbers; i++) {
            const index = i;
            inputs.push(<input type="number" value={this.state.input?.[String(i)] || ""} pattern="[0-9]{2}" placeholder="0" id="" name={"" + index} min="0" max="9" required="" onChange={(e) => this.playInput(e)} style={{ marginRight: 2 }} />);
        }

        let latestResults = [];
        // "Jumbo 2",
        let labels = ["", "Jumbo 1","Jumbo 2", "Magic 3", "Mega 4", "Magic 5", "Mega 6", "Mega 7","Cash Games"]
        for (var l in latest) {
            const data = latest[l];

            if (data.draws.length > 0) {
                let numbers = data.draws[0].winningNumbers;
                numbers = numbers?.split(",");
                let numbersList = numbers?.map(data => {
                    return <span>{data}</span>;
                });
                latestResults.push(
                    <div className="m-market">
                        <div className="table-body"><h5>{labels[data.index]}</h5>
                            <div className="td-row"><h6>ID</h6>{data.draws[0].id}</div>
                            <div className="td-row"><h6>Date</h6> {data.draws[0].date}</div>
                            <div className="td-row double-count"><h6 className="hr-left-margin">Draw</h6> {numbersList}</div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className="item">

                <section className="app-section">
                    <div className="main-body-section">
                        <Header />

                        <div id="demos" className="promo-carousel">
                            <div className="row">
                                <div className="large-12 columns">
                                    <div className="owl-carousel owl-theme">
                                        <div className="item">
                                            <div className="slide"> <img src="ui/images/banner-5.jpg" alt="" className="img-responsive" /> </div>
                                        </div>

                                        <div className="item">
                                            <div className="slide"> <img src="ui/images/banner-3.jpg" alt="" className="img-responsive" /> </div>
                                        </div>

                                        <div className="item">
                                            <div className="slide"> <img src="ui/images/banner-4.png" alt="" className="img-responsive" /> </div>
                                        </div>

                                        <div className="item">
                                            <div className="slide"> <img src="ui/images/banner-6.jpg" alt="" className="img-responsive" /> </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="_home_body_container">
                            <div className="m_boxs">
                                <div className="game-play-app">
                                    <div className="widget">
                                        <div className="stake-widget-app">
                                            <div className="bet-app animated bounceIn">

                                                <div id="game-options">
                                                    <h5>Select Game Option</h5>
                                                    <ul>
                                                        <li><a onClick={() => this.initGame(1)} className={(this.state.game == 1) ? "selected" : ""}>Jumbo 1</a></li>
                                                        {/* <li><a onClick={() => this.initGame(2)} className={(this.state.game == 2) ? "selected" : ""}>Jumbo 2</a></li> */}
                                                        <li><a onClick={() => this.initGame(3)} className={(this.state.game == 3) ? "selected" : ""}>Magic 3</a></li>
                                                        <li><a onClick={() => this.initGame(5)} className={(this.state.game == 5) ? "selected" : ""}>Magic 5</a></li>
                                                        <li><a onClick={() => this.initGame(6)} className={(this.state.game == 6) ? "selected" : ""}>Mega 6</a></li>
                                                        <li><a onClick={() => this.initGame(7)} className={(this.state.game == 7) ? "selected" : ""}>Mega 7</a></li>
                                                    </ul>
                                                </div>

                                                {/* <div class="container">
                                                    <div class="dropdown">
                                                        <div class={`caption ${this.state.game > 7 ? 'captionselect' : ''}`} style={{ backgroundColor: `${this.state.numbers > 7 ? '#fff' : ''}` }}>Cash Game</div>
                                                        <div class="list" style={{ zIndex: 99999 }}>
                                                            {Array(5).fill(1).map((a, i) => <div class="item" key={`cashgames${i}`} onClick={() => this.initGame(7 + i + 1)}>Cash {i + 1}</div>)}
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <form id="indexform" method="post">
                                                    <div>
                                                        <h5>Select Mobile Network</h5>
                                                        <label className="check-reset">
                                                            <img src="ui/images/mnos-mtn.png" />
                                                            <input disabled type="radio" name="operator" value="mtn-gh" defaultChecked="" onClick={() => this.setState({ operator: "mtn" })} checked={(this.state.operator == "mtn") ? true : false} />
                                                            <span className="checkmark"></span> </label>
                                                        <label className="check-reset">
                                                            <img src="ui/images/mnos-airtel.png" />
                                                            <input disabled type="radio" name="operator" value="tigo-gh" onClick={() => this.setState({ operator: "AIRTELTIGO" })} checked={(this.state.operator == "AIRTELTIGO") ? true : false} />
                                                            <span className="checkmark"></span> </label>
                                                        <label className="check-reset">
                                                            <img src="ui/images/mnos-voda.png" />
                                                            <input disabled type="radio" name="operator" value="vodafone-gh-ussd" onClick={() => this.setState({ operator: "vodafone" })} checked={(this.state.operator == "vodafone") ? true : false} />
                                                            <span className="checkmark"></span> </label>
                                                    </div>

                                                    <div>
                                                        <label htmlFor="" id="correctformat" style={{ display: "none" }}>enter the write format e.g 244709137</label>
                                                        <input type="tel" x-autocompletetype="tel" pattern="\d*" placeholder="Mobile Phone Number" id="mobile" name="mobile" required="" maxLength="9" disabled value={this.state.mobile.substring(3)} />
                                                        <label className="app-l telephone_digit">+233</label>
                                                    </div>
                                                    <div>
                                                        <h5>Choose {this.state.numbers} number(s) from {this.state.minNumber}-{this.state.limit}</h5>
                                                        <span>
                                                            <p id="game-dash-6" style={{ display: "block" }}>
                                                                {inputs}
                                                            </p>
                                                        </span>
                                                    </div>

                                                    <div>
                                                        <h5>Enter your stake (from GHC {this.state.minStake})</h5>
                                                        <label className="app-l currency">GHC</label>
                                                        <input name="amt" value={this.state.stake} onChange={(e) => this.setState({ stake: e.target.value })} type="number" required="" className="deposit-amount" placeholder={this.state.minStake + "-" + this.state.maxStake + ""} id="" min={this.state.minStake} max={this.state.maxStake} />
                                                    </div>
                                                    <div>
                                                        {btn_}
                                                    </div>
                                                    {(this.state.successMsg != "") &&
                                                        <div id="payment-notification" className="success-notification">
                                                            {this.state.successMsg}
                                                        </div>}
                                                    {(this.state.errorMsg != "") &&
                                                        <div id="payment-notification-error" className="error-notification">
                                                            {this.state.errorMsg}
                                                        </div>}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="homepage-intro_content"><h1>Start making money in a simple steps!</h1>Your all-day Cash alert every 10mins is assured.
                                    Follow prompts to play any of our games and win cash every 10mins! Stake with the best, stake with 213!

                                    <div className="_clickbtn"><a href="/results">Learn More</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="m_boxs results-section homepage-table"><h1 className="section-header">Latest Draw Results</h1>

                            <div className="draw-data-box">
                                {latestResults}
                            </div>
                        </div>

                        <div className="m_boxs promo-ms">
                            <div>
                                <div className="gift-box"></div>
                            </div>
                            <div id="page">

                                <div className="main promo-ads">
                                    <h1>Promotions</h1>

                                    <div className="grid grid-pad">
                                        <div className="col-1-2">
                                            <div className="content">
                                                <a href="http://wwww.facebook.com/213mobilegame" target="_bank">
                                                    <div className="promo_expire">expired</div>
                                                    <img src="ui/images/valsday-promo-10.png" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-1-2">
                                            <div className="content expire">
                                                <div className="promo_expire">expired</div>
                                                <img className="" src="ui/images/speedshop-promo.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <Footer />
                </section>

                <div className="m_game_bet_slip">
                    <div className="slip-box">
                        <small>GH₵</small><span>20000</span>
                        <ul style={{ display: 'none' }}>
                            <li><a href="#">Funds Deposit</a></li>
                            <li><a href="#">Game History</a></li>
                            <li><a href="#">Withdraw Funds</a></li>
                            <li><a href="#">Wallet Transactions</a></li>
                            <li><a href="#">Update Profile</a></li>
                            <li><a href="#">Change Login Password Edit</a></li>
                        </ul>
                    </div>
                    <div className="m_user-profile"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 428.7 468.4" style={{ enableBackground: 'new 0 0 428.7 468.4' }} xmlSpace="preserve">
                        <g>
                            <g>
                                <g>
                                    <path className="user-icon" d="M214.4,3.8c-65.8,0-119.6,53.8-119.6,119.6s53.8,119.6,119.6,119.6S334,189.3,334,123.4S280.2,3.8,214.4,3.8
				z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="user-icon" d="M419.7,338.7c-3.1-7.8-7.3-15.2-12-21.9c-24-35.5-61.1-59-102.9-64.8c-5.2-0.5-11,0.5-15.2,3.7
				c-21.9,16.2-48.1,24.6-75.2,24.6s-53.3-8.4-75.2-24.6c-4.2-3.1-9.9-4.7-15.2-3.7c-41.8,5.7-79.4,29.3-102.9,64.8
				c-4.7,6.8-8.9,14.6-12,21.9c-1.6,3.1-1,6.8,0.5,9.9c4.2,7.3,9.4,14.6,14.1,20.9c7.3,9.9,15.2,18.8,24,27.2
				c7.3,7.3,15.7,14.1,24,20.9c41.3,30.8,90.9,47,142.1,47s100.8-16.2,142.1-47c8.4-6.3,16.7-13.6,24-20.9
				c8.4-8.4,16.7-17.2,24-27.2c5.2-6.8,9.9-13.6,14.1-20.9C420.7,345.5,421.2,341.8,419.7,338.7z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    </div>
                </div>
            </div>
        );
    }
}
