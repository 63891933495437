import React, { Component } from 'react';
import history from '../history';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spinner from "../components/Spinner";

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,

        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {

    };

    render() {
        const { } = this.state;

        return (
            <>
                <section className="app-section">
                    <div className="main-body-section">
                        <Header />
                        <div className="m-register-banner">
                            <img src="ui/images/result-draw.jpg" alt="" title="" />
                            <span className="back-to-page">
                                <a href="#" onClick="window.history.go(-1)"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 492 420.22" style={{ enableBackground: 'new 0 0 492 420.22' }} xmlSpace="preserve">
                                    <g>
                                        <g>
                                            <path className="b-arrow" d="M0,210.12c-0.02,7.22,2.78,14.01,7.86,19.1L185.54,406.9c5.07,5.07,11.83,7.87,19.04,7.87
			c7.22,0,13.97-2.76,19.04-7.84l16.13-16.08c5.07-5.07,7.86-11.74,7.86-18.95c0-7.21-2.79-13.79-7.86-18.86L134.93,248.57h330.5
			c14.85,0,26.58-12.8,26.58-27.65v-22.81c0-14.85-11.73-26.48-26.58-26.48H136.1L239.76,67.75c5.07-5.06,7.86-12.06,7.86-19.26
			c0-7.21-2.79-13.98-7.86-19.04l-16.13-16.14c-5.07-5.07-11.83-7.86-19.04-7.86c-7.21,0-13.97,2.78-19.04,7.86L7.86,191
			C2.78,196.08-0.02,202.87,0,210.12z"/>
                                        </g>
                                    </g>
                                </svg></a>

                            </span>
                        </div>

                        <div className="_home_body_container">
                            <div className="m_boxs users_dashboard">
                                <div>
                                    <div className="js-pagination">
                                        <div data-position="0" style={{ display: 'block' }}>
                                            <h1 className="head-text-seletected bar_line_seletected seletected-game-draw">Terms and Conditions</h1>


                                            <div className="draw-data-box pages-text">

                                                Definitions<br /><br />

                                                <strong>BET/STAKE:</strong> The mechanism used by the player to enter the game.<br /><br />

                                                <strong>Draw:</strong> The Promoter uses this process which results in the random selection of a set of Winning numbers for a game.<br /><br />

                                                <strong>Draw Procedures: </strong>The draw procedures that apply to a Draw, as determined by the Promoter from time to time.<br /><br />

                                                <strong>Game:</strong> An event or outcome which is determined by a Draw.<br /><br />

                                                <strong>Mobile Money:</strong> Mobile Wallets of MTN and AirtelTigo and Vodafone<br /><br />

                                                <strong>Prize:</strong> A Prize of cash or including non-cash Prizes;<br /><br />

                                                <strong>Ticket Number:</strong> The unique number in the ticket which identifies your particular bet/stake, will be recorded on the Promoters computer system.<br /><br />

                                                <strong>Winning Numbers:</strong> The numbers that are drawn from the Random Number Generator (RNG).<br />
                                                <br />


                                                <strong>The Game:</strong>

                                                The title of the game 213 Games powered by GamesTech. GamesTech is a hub for numbers based and ticket-based games. These games include Magic Games (Magic 3 & 5), {/* Cash Games (Cash 1 - 6) */} and SupaCash with a draw time of 10mins.<br /><br />

                                                <strong>How to Enter:</strong>

                                                3.1. The competition will run on USSD code *213#, SMS AND online.

                                                3.2. To enter the competition online, simply choose the network you have a mobile money account with, enter your mobile number, type your selected number(s) and submit the amount of money you want to bet with, wait for mobile money pin request & approve to confirm entry into the 10 min draw.

                                                3.3. Read and accept terms and conditions.

                                                3.4. Dial *213# (across all networks), you become a player and get the chance to win;

                                                a) Every game under GamesTech offers you a chance to win by the multipliers for the game selected<br /><br />

                                                <strong>a) Magic Games</strong><br />

                                                {/* <strong>b) Cash Games</strong><br /> */}

                                                <strong>b) SupaCash</strong><br /><br />

                                                3.5. The winning numbers for each draw will be generated on a random basis. These winning numbers are randomly generated every 10 minutes by a Certified - Random Number Generator (RNG) engine. Upon entry into a draw, players get a confirmation by text of their selections and the Draw they are in.

                                                3.6. The Promoter will not accept responsibility for competition entries that are, mislaid, damaged or delayed in transit, regardless of cause, including, for example, as a result of any equipment failure, technical malfunction, systems, satellite, network, server, computer hardware or software failure of any kind.

                                                3.7. By submitting a competition entry, you are agreeing to be bound by these terms and conditions.

                                                3.8. For help with entries, please contact the Consumer Helpline 213 on all networks OR www.gamebox.com.gh 3.9. Please see https://gamebox.com.gh/ for a copy of these competition terms and conditions.

                                                3.10. The stake for each Draw is between GHC 2 minimum to GHC 500 maximum.

                                                3.10. The results can be checked by visiting https://gamebox.com.gh/. The results will also be sent to all players that made a Bet in the game via SMS.

                                                3.11. The draw is done every 10 minutes of every day (including weekends)– However the time, frequency, date and method of the draws for each game shall be decided by the promoter.

                                                3.12. If there is equipment failure or failure of any other sort or for another reason a draw is not completed and therefore cannot take place on the date and time fixed, it may take place as soon as practically possible after that date.

                                                3.13. If any draw is deemed invalid a further draw will take place in line with the draw procedures to decide the Winning Numbers.

                                                3.14. A Prize will be paid by the Promoter based only on official results of any relevant draw recorded in the promoter’s computer system. If a prize is notified in the newspapers or on any websites or other medium, the promoter shall not pay the prize.<br /><br />

                                                <strong>4. Eligibility</strong>

                                                4.1. The competition is only open to all residents in Ghana aged 18 years and over except;

                                                (a) Employees of the Promoter or its holding or subsidiary companies.

                                                (b) Employees of agents or suppliers of the Promoter or its holding or subsidiary companies, who are professionally connected with the competition or its administration.

                                                (c) Members of the immediate families or households of (a) and (b) above.

                                                4.2. In entering the competition, you confirm that you are eligible to do so and eligible to claim any prize you may win. The Promoter may require you to provide proof that you are eligible to enter the competition.

                                                4.3. The Promoter will not accept competition entries that are;

                                                (a) Automatically generated by computer.

                                                (b) Completed by third parties or in bulk.

                                                4.4. The Promoter reserves all rights to disqualify you if your conduct is contrary to the spirit or intention of the prize competition. <br /><br />

                                                <strong>5. The Prizes and Withholding Tax</strong>

                                                5.1. The prizes are calculated using the following multipliers across games.<br /><br />

                                                {/* <strong>Cash 5 Multiplier</strong>

                                                Match 5 in order {'>>'} 800X Match 4 in order {'>>'} 200X Match 3 in order {'>>'} 40X Match 2 in order {'>>'} 7X<br /><br />

                                                <strong>Cash 4 Multiplier</strong>

                                                Match 4 in order {'>>'} 250X Match 3 in order {'>>'} 30X Match 2 in order {'>>'} 7X

                                                <br />
                                                <br />
                                                <strong>Cash 3 Multiplier</strong>

                                                Match 3 in order {'>>'} 150X Match 2 in order {'>>'} 11X

                                                <br />
                                                <br />
                                                <strong>Cash 2 Multiplier</strong>

                                                Match 2 in order {'>>'} 25X Match 1 in order {'>>'} 4X Cash 1 Multiplier Match 1 in order {'>>'} 7X Magic 5 Multiplier

                                                <br /> */}
                                                <br />
                                                <strong>Magic 3 Multiplier</strong>

                                                Match 3 in order {'>>'} 35X Match 4 in order {'>>'} 150X Match 5 in order {'>>'} 500X Match 3 in any order {'>>'} 1.5X Match 4 in any order {'>>'} 3X Match 5 in any order {'>>'} 15X

                                                5.2. The Prize is not negotiable or transferable.

                                                Match 1 in order {'>>'} 1.5X 2 in any order {'>>'} 3X 3 in any order {'>>'} 15X

                                                5.3. After each draw and announcement of winners, their prize money shall be paid directly into their account unless the Promoter at their discretion decides to present any given prize directly to the winner.

                                                5.4. Prizes that are over GHC 10,000 will not be paid via mobile wallets. Winners will be contacted so that they can be presented with their prize.

                                                5.5. There is a VAT, NHIL & GETFUND tax on all bets and winnings in accordance with Ghana Gaming Laws. However, the Promoter will pay this on behalf of the winners and as such winners will not be deducted with taxes.<br /><br />

                                                <strong>6. Winner announcement</strong>

                                                6.1. The winner of the competition will be announced every 10 minutes. Each draw will close 30 seconds before each draw. If you play after that time you will automatically go into the next draw.

                                                6.2. The decision of the Promoter is final, and no correspondence or discussion will be entered.

                                                6.3. Winners will have their money sent directly to their mobile wallet after the draw if they win any amount up to GHC10,000. Payment of wins may be affected by limit on winners mobile money account and as a result the Promoter will pay in bits per the limit on the winner’s mobile money account. 6.4. Winners who win more than GHC 10,000 will have to collect their winnings via a cheque payment. Promoter will contact these winners via SMS or through our Customer Service Executives.

                                                6.5. The Promoter reserves the right to contact the winner personally as soon as practicable after the win, using the telephone number or email address provided in the competition entry. The Promoter will not amend any contact information once the competition entry has been submitted.

                                                6.6. A list of Winners can be viewed on www.gamebox.com.gh for a period of 14 days (every 2 weeks). The Promoter will endeavour to ensure that the results (including Prize Breakdowns) and winning prizes displayed on the Website or published via other sources are accurate.

                                                Although every effort is made to ensure the accuracy of this information, the promoter shall not be liable for any mistakes in respect of such information.

                                                6.7. If required by law, or by any court, or by any regulations, the promoter may provide your details to a third party if you win a prize and full details of any prize paid to you.

                                                6.8. From time to time the promoter may send you messages of a promotional nature through any contact details provided to the promoter upon registration until you opt out of this service.

                                                6.9. The Promoter may be entitled at any time to withdraw or suspend or close any Game at any time at its absolute discretion.
                                                <br /><br />
                                                <strong>7. Claiming the prize</strong>

                                                7.1. If the Promoter decides to provide the prize to a winner face to face at the Promoter’s office the following provisions apply;

                                                7.2. The Prize shall not be claimed by a third party on your behalf.

                                                7.3. The Promoter will make all reasonable efforts to contact the winner. If the winner cannot be contacted or is not available or has not claimed their prize within 14 days of the win, the promoter reserves the right to offer the prize to the next eligible entrant selected from the correct entries that were received for that Draw.

                                                7.4. The promoter does not accept any responsibility if you are not able to take up the Prize. 7.5. You are not entitled to claim a Prize using interactive betting slips or any purchase confirmation provided by the Promoter.

                                                7.6. Each Prize must be claimed before the end of the day after the Draw (“the Claim

                                                Period”) using the claim procedure as set out in clause 7.7 below and if the procedure in clause

                                                7.7 is not used to claim the Prize within the Claim Period, then you shall lose the prize and the prize will not be paid by the Promoter.

                                                7.7. A Prize can be claimed in any one of the following ways: -

                                                a) By contacting the Consumer Helpline (0307000613) on a 24hour daily basis which is fully displayed on the website;

                                                b) By attendance at the offices of the Promoter during the promoter’s normal office hours.

                                                7.8. If you have not claimed your prize within the Claim Period, you can for a further period of 14 working days after the Claim Period contact the Promoter in the following ways and providing all the information the promoter requires so that the Promoter may consider your request;

                                                a) Phoning the consumer Helpline during its normal opening hours from time to time.

                                                c) If you fail to claim the Prize within the additional 5-day period, your entitlement to the Prize will be lost and the prize will not be paid.

                                                7.9. Any Prize prior to payment shall be validated by the Promoter in line with its procedures as notified on the website from time to time and the Promoters decision shall be final and binding on you saved for manifest error.

                                                7.10. A Bet shall be invalid if any of the following events occur: -

                                                a) The Bet is placed to increase the chance of any person winning a Prize, or to increase the prize for that Draw.

                                                b) A Prize is not claimed within the Claim Period under clause 7.7

                                                c) The Bet is not in the list published on the Promoters website.

                                                d) The Bet has in any way been forged.

                                                e) The Bet fails the Promoters validation check.

                                                f) The Promoter cannot match the ticket number to any records held by the promoter on its computer systems. g) The Promoter at his discretion is concerned about the validity of the Bet.

                                                h) The Promoter believes that the Prize is being claimed by someone who is not the account holder.

                                                i) The Bet has been tampered with.

                                                j) The Bet has not been issued or sold by the Promoter.

                                                7.11. The following rights are always reserved to the Promoter at its sole discretion: -

                                                a) Make payment of any Prize by any means including, by bank transfer, direct credit, Mobile Money or by cheque.

                                                b) Until such time as a Bet has been validated withhold the Prize until all validation has been completed to the Promoter’s satisfaction.

                                                c) Request you to attend its offices to claim the Prize;

                                                d) Undertake any relevant validation or security checks at its discretion without any recourse to you and invalidate any Bets as a result of any problems with the Bet;

                                                e) Request proof of identification and entitlement to claim the Prize;

                                                f) Refuse to pay a Prize.

                                                g) Keep records of anybody claiming a Prize including photographic evidence.

                                                h) Refer the matter to a court or government agency to consider whether a prize should be paid;

                                                i) Refuse to pay a Prize if you refuse to sign all the documentation required by the Promoter.

                                                7.12. If a Prize has already been claimed fraudulently by the third party the promoter shall not be obliged to repay the Prize to you.

                                                7.13. If the Promoter withholds payment of the Prize for whatever reason you shall not be entitled to any interest or compensation whilst matters are being resolved and the Prize remains unpaid.

                                                7.14. You are responsible for the payment of all taxes levied by any government authority to be deducted from your account in respect of the Prize and any charges levied by the mobile operator payment gateways will also be deducted from your account. 7.15 A reversal is done when there is a wrongful Deduction (A wrongful deduction is when a customer attempts to buy a ticket from us and receive confirmation of payment from the telco but the ticket is not registered on our systems)

                                                When this happens, a daily reconciliation picks up these issues or when a customer reaches out to our customer service agents, the issue is logged into our systems and forwarded to the technical team for the customer to be refunded. Refunds happen from 24 to 72 hours maximum.

                                                <br />
                                                <br />
                                                <strong>8. Limitation of liability</strong>

                                                8.1. Insofar as is permitted by law, the Promoter, its agents or distributors will not in any circumstances be responsible or liable to compensate the winner or accept any liability for any loss, damage, personal injury or death occurring as a result of taking up the prize except where it is caused by the negligence of the Promoter, its agents or distributors or that of their employees. Your statutory rights are not affected.

                                                8.2. In the event of a dispute arising from any non-payment or payment of Prizes the Promoters decision shall be final and saved for the Promoter may at its sole discretion reimburse the cost of the Bet to you.

                                                8.3. The Promoters liability to you shall be limited to the amount of the Bet.

                                                8.4. The Promoter may withhold payment of a Prize until any dispute has been resolved.

                                                9. Ownership of competition entries and intellectual property rights

                                                9.1. All competition entries and any accompanying material submitted to the Promoter will become the property of the promoter on receipt and will not be returned.

                                                9.2. You agree that the Promoter may, but is not required to, make your entry available on its website www.gamebox.com.gh and any other media, whether now known or invented in the future, and in connection with any publicity of the competition. You agree to grant the promoter a non-exclusive, worldwide, irrevocable license, for the full period of any intellectual property rights in the competition entry and any accompanying materials, to use, display, publish, transmit, copy, edit, alter, store, re-format and sub-license the competition entry and any accompanying materials for such purposes. 10. Data protection and publicity

                                                10.1. If you are the winner of the competition, you agree that the Promoter may use your name, image and town or county of residence to announce the winner of this competition and for any other reasonable and related promotional purposes.

                                                10.2. You further agree to participate in any reasonable publicity required by the Promoter.

                                                10.3. By entering the competition, you agree that any personal information provided by you in the competition entry may be held and used only by the promoter or its agents and suppliers to administer the competition.<br /><br />

                                                <strong>11. General</strong>

                                                11.1. If there is any reason to believe that there has been a breach of these terms and conditions, the promoter may, at its sole discretion, reserve the right to exclude you from participating in the competition.

                                                11.2. The Promoter reserves the right to hold void, suspend, cancel, or amend the prize competition where it becomes necessary to do so.

                                                11.3. These terms and conditions shall be governed by Ghanaian law, and the parties submit to the non-exclusive jurisdiction of the courts of Ghana.
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </section>
                <div className="m_game_bet_slip">
                    <div className="slip-box">
                        <small>GH₵</small><span>20000</span>
                        <ul style={{ display: 'none' }}>
                            <li><a href="#">Funds Deposit</a></li>
                            <li><a href="#">Game History</a></li>
                            <li><a href="#">Withdraw Funds</a></li>
                            <li><a href="#">Wallet Transactions</a></li>
                            <li><a href="#">Update Profile</a></li>
                            <li><a href="#">Change Login Password Edit</a></li>
                        </ul>
                    </div>
                    <div className="m_user-profile"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 428.7 468.4" style={{ enableBackground: 'new 0 0 428.7 468.4' }} xmlSpace="preserve">
                        <g>
                            <g>
                                <g>
                                    <path className="user-icon" d="M214.4,3.8c-65.8,0-119.6,53.8-119.6,119.6s53.8,119.6,119.6,119.6S334,189.3,334,123.4S280.2,3.8,214.4,3.8
				z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="user-icon" d="M419.7,338.7c-3.1-7.8-7.3-15.2-12-21.9c-24-35.5-61.1-59-102.9-64.8c-5.2-0.5-11,0.5-15.2,3.7
				c-21.9,16.2-48.1,24.6-75.2,24.6s-53.3-8.4-75.2-24.6c-4.2-3.1-9.9-4.7-15.2-3.7c-41.8,5.7-79.4,29.3-102.9,64.8
				c-4.7,6.8-8.9,14.6-12,21.9c-1.6,3.1-1,6.8,0.5,9.9c4.2,7.3,9.4,14.6,14.1,20.9c7.3,9.9,15.2,18.8,24,27.2
				c7.3,7.3,15.7,14.1,24,20.9c41.3,30.8,90.9,47,142.1,47s100.8-16.2,142.1-47c8.4-6.3,16.7-13.6,24-20.9
				c8.4-8.4,16.7-17.2,24-27.2c5.2-6.8,9.9-13.6,14.1-20.9C420.7,345.5,421.2,341.8,419.7,338.7z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    </div>
                </div>
            </>
        );
    }
}
