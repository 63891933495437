import logo from './logo.svg';
import './App.css';
import { Router, Switch, Route } from 'react-router-dom';

import Index from './pages/index';
import Results from './pages/results';
import Register from './pages/register';
import Recovery from './pages/recovery';
import Profile from './pages/profile';
import Logout from './pages/logout';
import Resetpassword from './pages/resetpassword';
import Multipliers from './pages/Multipliers';
import About from './pages/About';
import TNC from './pages/Tnc';
import Contact from './pages/Contact';
import News from './pages/News';
import Promotions from './pages/Promotions';
import Howtoplay from './pages/Howtoplay';
import Tnc from './pages/Tnc';
import Jumbo from './pages/Jumbo';
import Mega from './pages/Mega';
import Magic from './pages/Magic';
import history from './history';

function App() {
    return (
        <Router history={history}>
      <Switch>
        <Route exact path='/' render={(props) => <Index {...props} />} />
        <Route exact path='/results' render={(props) => <Results {...props} />} />
        <Route exact path='/register' render={(props) => <Register {...props} />} />
        <Route exact path='/recovery' render={(props) => <Recovery {...props} />} />
        <Route exact path='/profile' render={(props) => <Profile {...props} />} />
        <Route exact path='/logout' render={(props) => <Logout {...props} />} />
        <Route exact path='/resetpassword' render={(props) => <Resetpassword {...props} />} />
        <Route exact path='/multipliers' render={(props) => <Multipliers {...props} />} />
        <Route exact path='/news' render={(props) => <News {...props} />} />
        <Route exact path='/contact' render={(props) => <Contact {...props} />} />
        <Route exact path='/promo' render={(props) => <Promotions {...props} />} />
        <Route exact path='/about' render={(props) => <About {...props} />} />
        <Route exact path='/howtoplay' render={(props) => <Howtoplay {...props} />} />
        <Route exact path='/tnc' render={(props) => <Tnc {...props} />} />
        <Route exact path='/jumbo' render={(props) => <Jumbo {...props} />} />
        <Route exact path='/magic' render={(props) => <Magic {...props} />} />
        <Route exact path='/mega' render={(props) => <Mega {...props} />} />
      </Switch>
    </Router>
    );
}

export default App;