import React, { Component } from 'react';
import history from '../history';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spinner from "../components/Spinner";

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,

        };
    }

    componentDidMount() {
        localStorage.removeItem("userD");
        history.push('/');
        window.location.href = window.location.href;
    }

    componentDidUpdate(){
        localStorage.removeItem("userD");
        history.push('/');
        window.location.href = window.location.href;
    }


    render() {
        const { } = this.state;

        return (
            <>
                
            </>
        );
    }
}
