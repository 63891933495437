import React, { Component } from 'react';
import history from '../history';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Spinner from "../components/Spinner";
import { PostData, baseUrl } from '../common';
import axios from 'axios';
import qs from 'qs';

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fname: "",
            lname: "",
            email: "",
            mobile: "",
            operator: "",
            password: "",
            confirmpassword: "",
            errorMsg: "",
            confirmation: false,
            tokens: ["", "", "", "", "", ""],
            session_token: ""
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        var activate=await localStorage.getItem("activate");
        if(activate!=null)
            this.setState({confirmation:true});
    };

    setToken = async (value, index) => {
        var tokens = this.state.tokens;
        tokens[index] = value;
        this.setState({ tokens: tokens });

        if(index==0 && value!=""){console.log("here");
            this.secComponent.focus();
        }else if(index==1 && value!="")
            this.thirdComponent.focus();
        else if(index==2 && value!="")
            this.forthComponent.focus();
        else if(index==3 && value!="")
            this.fifthComponent.focus();
        else if(index==4 && value!="")
            this.sixthComponent.focus();
    };

    register = async () => {
        if (this.state.fname == "") {
            this.setState({ errorMsg: "First name is required" });
        } else if (this.state.lname == "") {
            this.setState({ errorMsg: "Last name is required" });
        } else if (this.state.mobile == "") {
            this.setState({ errorMsg: "Mobile number is required" });
        } else if (this.state.operator == "") {
            this.setState({ errorMsg: "Mobile operator is required" });
        } else if (this.state.email == "") {
            this.setState({ errorMsg: "Email address is required" });
        } else if (this.state.password == "") {
            this.setState({ errorMsg: "Password is required" });
        } else if (this.state.password != this.state.confirmpassword) {
            this.setState({ errorMsg: "Passwords do not match" });
        } else {
            this.setState({ loading: true });
            var params = qs.stringify({
                'password': this.state.password,
                'confirmPassword': this.state.confirmpassword,
                'mobileNumber': (!this.state.mobile.startsWith("0"))?"233"+this.state.mobile:"233"+this.state.mobile.substring(1),
                'action': 'register',
                'email':this.state.email,
                'firstName': this.state.fname,
                'lastName': this.state.lname,
                'operator': this.state.operator
            });

            var self = this;
            this.setState({ loading: true });
            axios.post(baseUrl, params)
                .then(function (response) {
                    if (response.data.status === 1) {
                        try {
                            localStorage.setItem("userDataRegister", JSON.stringify(response.data));
                            localStorage.setItem("userDataRegisterNumber", "233" + self.state.mobile);
                            localStorage.setItem("userDataRegisterpassword", self.state.password);
                            
                            self.setState({ session_token: response.data.token });

                        } catch (error) {
                            console.log(error);
                        }

                        self.setState({
                            operator: '',
                            lname: '',
                            fname: '',
                            password: '',
                            confpassword: '',
                            mobile: '',
                            confirmation: true,
                            errorMsg: response.data.message
                        });
                    } else {
                        self.setState({ errorMsg: response.data.message });
                    }
                    self.setState({ loading: false });
                })
                .catch(error => {
                    self.setState({ loading: false, errorMsg: "Sorry unable to proceed your request at this time." });
                });
        }
    };

    resendToken = async () => {
        this.setState({ loading: true });
        var username=localStorage.getItem("userDataRegisterNumber");
        var params = qs.stringify({
            'token':this.state.session_token,
            'msisdn':username,
            'action':"resendregcode"
        });

        var params = qs.stringify({
            'mobileNumber': username,
            'action': 'resendregcode',
            'token':this.state.session_token
        });

        var self = this;
        this.setState({ loading: true });
        axios.post(baseUrl, params)
            .then(function (response) {
                self.setState({ errorMsg: response.data.message });
            })
            .catch(error => {
                self.setState({ loading: false, errorMsg: "Sorry unable to proceed your request at this time." });
            }).finally(()=>{
                self.setState({ loading: false });
            });
    };

    activation = async () => {
        var valid = true;
        for (var t in this.state.tokens) {
            if (this.state.tokens[t] == "") {
                valid = false;
            }
        }

        if (valid == false) {
            this.setState({ errorMsg: "Invalid token , kindly fill all slots" });
        } else {
            this.setState({ loading: true });
            var mobileNumber = await localStorage.getItem("userDataRegisterNumber");
            var params = qs.stringify({
                'mobileNumber': mobileNumber,
                'action': 'confirmation',
                'code': this.state.tokens.join(""),
                'type': "2",
                'token': this.state.session_token
            });

            var self = this;
            this.setState({ loading: true });
            axios.post(baseUrl, params)
                .then(function (response) {
                    if (response.data.status === 1) {
                        self.setState({
                            operator: '',
                            lname: '',
                            fname: '',
                            password: '',
                            confpassword: '',
                            mobile: '',
                            confirmation: true
                        });
                        self.Login();
                    } else {
                        self.setState({ errorMsg: response.data.message });
                    }
                    self.setState({ loading: false });
                })
                .catch(error => {
                    console.log(error);
                    self.setState({ loading: false, errorMsg: "Sorry unable to proceed your request at this time." });
                });

        }
    };

    Login() {

        let self = this;
        this.setState({ loading: true });

        var username = localStorage.getItem("userDataRegisterNumber");
        var password = localStorage.getItem("userDataRegisterpassword");

        var params = new URLSearchParams();
        params.append('password', password);
        params.append('type', 1);
        params.append('mobileNumber', username);
        params.append('action', 'login');

        axios.post(baseUrl, params)
            .then(function (response) {
                self.setState({ loading: false });
                if (response.data.status === 1) {
                    localStorage.setItem("userD", JSON.stringify(response.data));
                    self.setState({ processing: false });
                    history.push('/');
                    window.location.href = window.location.href;
                } else {
                    self.setState({ error: response.data.message });
                }
                self.setState({ processing: false });
            })
            .catch(error => {
                // console.log(error);
                self.setState({ processing: false });
            });
    }


    render() {
        const { errorMsg } = this.state;

        var mobile = localStorage.getItem("userDataRegisterNumber");
        return (
            <>
                <section className="app-section">
                    <div className="main-body-section">
                        <Header />


                        <div className="m-register-banner">
                            <img src="ui/images/68786597-e2c0-460d-974f-1798d75f1dfc.png" alt="" title="" /><span className="back-to-page">
                                <a href="#" onClick="window.history.go(-1)"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 492 420.22" style={{ enableBackground: 'new 0 0 492 420.22' }} xmlSpace="preserve">
                                    <g>
                                        <g>
                                            <path className="b-arrow" d="M0,210.12c-0.02,7.22,2.78,14.01,7.86,19.1L185.54,406.9c5.07,5.07,11.83,7.87,19.04,7.87
			c7.22,0,13.97-2.76,19.04-7.84l16.13-16.08c5.07-5.07,7.86-11.74,7.86-18.95c0-7.21-2.79-13.79-7.86-18.86L134.93,248.57h330.5
			c14.85,0,26.58-12.8,26.58-27.65v-22.81c0-14.85-11.73-26.48-26.58-26.48H136.1L239.76,67.75c5.07-5.06,7.86-12.06,7.86-19.26
			c0-7.21-2.79-13.98-7.86-19.04l-16.13-16.14c-5.07-5.07-11.83-7.86-19.04-7.86c-7.21,0-13.97,2.78-19.04,7.86L7.86,191
			C2.78,196.08-0.02,202.87,0,210.12z"/>
                                        </g>
                                    </g>
                                </svg></a>

                            </span>
                        </div>

                        <div className="_home_body_container">

                            <div className="m_boxs">
                                <div>
                                    <div className="js-pagination">
                                        {(this.state.confirmation == false) &&
                                            <div>
                                                <div data-position="0" style={{ display: 'block' }}>
                                                    <h1>Create Your 213 Games Account</h1>
                                                    {(errorMsg != "") && <strong style={{ color: '#f00' }}>{errorMsg}</strong>}
                                                    <div className="kc-input" style={{ width: '48%' }}>
                                                        <input type="text" placeholder="First Name" value={this.state.fname} onChange={(e) => this.setState({ fname: e.target.value })} />
                                                    </div>
                                                    <div className="kc-input" style={{ width: '48%', float: 'right', marginTop: -47 }}>
                                                        <input type="text" placeholder="Last Name" value={this.state.lname} onChange={(e) => this.setState({ lname: e.target.value })} />
                                                    </div>

                                                    <div className="kc-input">
                                                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAoBAMAAABQlYTMAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAwUExURckRJgFoPs6+HOZ0HvXLF9htHQNiPPzRFgBrP84RJgICAHdjCjIpA8CfEK2PDldIB4Tm3tkAAAAHdFJOU+fnxMPnxd0CDR1PAAAAgklEQVQ4y2NgwA84Z+IBE0alaSEdbIwMTNG4DO7lyKDuOQq3BE26/jte6arleKVfrcMtXbt796k1u3dfx6V71yogWI3T8NpTq1atuY7b7ppVq47jcVrVqlXL8UjfWnNqLR7pXdurV+ORPltefgdVWgkvYJDowAMaR6VpIZ0oiAeIAQDzmkWkrJNtsQAAAABJRU5ErkJggg==
    "/>
                                                        <span className="code-value">+233</span>
                                                        <input type="tel" placeholder="Phone Number" value={this.state.mobile} onChange={(e) => this.setState({ mobile: e.target.value })} />
                                                    </div>

                                                    <div className="misdn" style={{marginTop:20,marginBottom:20}}>
                                                        <p style={{color:'#fff'}}>Select Mobile Network</p>
                                                        <label className="check-reset">
                                                            <img src="ui/images/mnos-mtn.png" style={{width:'10%'}}/>
                                                            <input type="radio" name="operator" value="mtn-gh" checked={(this.state.operator=="mtn")?true:false} onClick={()=>this.setState({operator:"mtn"})}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <label className="check-reset">
                                                            <img src="ui/images/mnos-airtel.png" style={{width:'10%'}}/>
                                                            <input type="radio" name="operator" value="tigo-gh" checked={(this.state.operator=="AIRTELTIGO")?true:false} onClick={()=>this.setState({operator:"AIRTELTIGO"})}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <label className="check-reset">
                                                            <img src="ui/images/mnos-voda.png" style={{width:'10%'}}/>
                                                            <input type="radio" name="operator" value="vodafone-gh-ussd" checked={(this.state.operator=="vodafone")?true:false} onClick={()=>this.setState({operator:"vodafone"})}/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>

                                                    <div className="kc-input">
                                                        <input type="email" placeholder="Email Address" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                                    </div>
                                                    {/* {(this.state.loading == false) ? <button className="v-button" value="Submit" data-next="1" onClick={() => this.register()}>Submit</button> : <center><Spinner fill="#fff" bg="#202341" /></center>} */}

                                                </div>

                                                <br />
                                                <div data-position="1" style={{ display: 'block' }}>
                                                    <h1>Set Your Password</h1>

                                                    <div className="kc-input"><svg version="1.1" id="bold" xmlns="http://www.w3.org/2000/svg" xmlnslink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 79.8 106.6" style={{ enableBackground: 'new 0 0 79.8 106.6' }} xmlSpace="preserve">
                                                        <path className="pd-icon" d="M69.7,40.1h-3.3V26.8c0-14.6-11.9-26.5-26.5-26.5S13.4,12.2,13.4,26.8v13.2h-3.3c-5.5,0-9.9,4.5-9.9,9.9v46.4
        c0,5.5,4.5,9.9,9.9,9.9h59.6c5.5,0,9.9-4.5,9.9-9.9V50C79.6,44.5,75.2,40.1,69.7,40.1z M22.2,26.8c0-9.7,7.9-17.7,17.7-17.7
        s17.7,7.9,17.7,17.7v13.2H22.2V26.8z M44.3,74.2v10.1c0,2.4-2,4.4-4.4,4.4s-4.4-2-4.4-4.4V74.2c-2.6-1.5-4.4-4.3-4.4-7.6
        c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8C48.7,69.8,46.9,72.7,44.3,74.2z"/>
                                                    </svg><input type="password" placeholder="Password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                                    </div>
                                                    <div className="kc-input"><svg version="1.1" id="bold" xmlns="http://www.w3.org/2000/svg" xmlnslink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 79.8 106.6" style={{ enableBackground: 'new 0 0 79.8 106.6' }} xmlSpace="preserve">
                                                        <path className="pd-icon" d="M69.7,40.1h-3.3V26.8c0-14.6-11.9-26.5-26.5-26.5S13.4,12.2,13.4,26.8v13.2h-3.3c-5.5,0-9.9,4.5-9.9,9.9v46.4
        c0,5.5,4.5,9.9,9.9,9.9h59.6c5.5,0,9.9-4.5,9.9-9.9V50C79.6,44.5,75.2,40.1,69.7,40.1z M22.2,26.8c0-9.7,7.9-17.7,17.7-17.7
        s17.7,7.9,17.7,17.7v13.2H22.2V26.8z M44.3,74.2v10.1c0,2.4-2,4.4-4.4,4.4s-4.4-2-4.4-4.4V74.2c-2.6-1.5-4.4-4.3-4.4-7.6
        c0-4.9,4-8.8,8.8-8.8s8.8,4,8.8,8.8C48.7,69.8,46.9,72.7,44.3,74.2z"/>
                                                    </svg><input type="password" placeholder="Password" value={this.state.confirmpassword} onChange={(e) => this.setState({ confirmpassword: e.target.value })} />
                                                    </div>
                                                    <small className="label">Use 6-14 charachers with at least one letter and one number.</small>
                                                    {(this.state.loading == false) ? <button className="v-button" value="Submit" data-next="1" onClick={() => this.register()}>Create Account</button> : <center><Spinner fill="#fff" bg="#202341" /></center>}
                                                </div>
                                            </div>
                                        }

                                        {(this.state.confirmation == true) &&
                                            <div data-position="2" style={{ display: 'block' }}>
                                                <h1>Verify Your Mobile Number</h1>
                                                {(errorMsg != "") && <strong style={{ color: '#f00' }}>{errorMsg}</strong>}

                                                <small className="label-2">We've sent you a 6-digit code to +{mobile}. Enter the code to verify your Mobile Number</small>

                                                <div className="pin_verified-box">
                                                    <input type="tel" name="" id="1" className="inputs" maxlength="1" value={this.state.tokens[0]} onChange={(e) => { this.setToken(e.target.value, 0) }} ref={c => this.firstComponent=c}/>
                                                    <input type="tel" name="" id="2" className="inputs" maxlength="1" value={this.state.tokens[1]} onChange={(e) => { this.setToken(e.target.value, 1) }} ref={c => this.secComponent=c}/>
                                                    <input type="tel" name="" id="3" className="inputs" maxlength="1" value={this.state.tokens[2]} onChange={(e) => { this.setToken(e.target.value, 2) }} ref={c => this.thirdComponent=c}/>
                                                    <input type="tel" name="" id="4" className="inputs" maxlength="1" value={this.state.tokens[3]} onChange={(e) => { this.setToken(e.target.value, 3) }} ref={c => this.forthComponent=c}/>
                                                    <input type="tel" name="" id="5" className="inputs" maxlength="1" value={this.state.tokens[4]} onChange={(e) => { this.setToken(e.target.value, 4) }} ref={c => this.fifthComponent=c}/>
                                                    <input type="tel" name="" id="6" className="inputs" maxlength="1" value={this.state.tokens[5]} onChange={(e) => { this.setToken(e.target.value, 5) }} ref={c => this.sixthComponent=c}/>
                                                </div>
                                                {(this.state.loading == false) ? <button className="v-button" value="Submit" data-next="1" onClick={() => this.activation()}>Verify Account</button> : <center><Spinner fill="#fff" bg="#202341" /></center>}
                                                <span className="m_link_signup resend"><a href="#" onClick={()=>this.resendToken()}>Send Code Again</a></span>

                                            </div>}

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                    <Footer />
                </section>
                <div className="m_game_bet_slip">
                    <div className="slip-box">
                        <small>GH₵</small><span>20000</span>
                        <ul style={{ display: 'none' }}>
                            <li><a href="#">Funds Deposit</a></li>
                            <li><a href="#">Game History</a></li>
                            <li><a href="#">Withdraw Funds</a></li>
                            <li><a href="#">Wallet Transactions</a></li>
                            <li><a href="#">Update Profile</a></li>
                            <li><a href="#">Change Login Password Edit</a></li>
                        </ul>
                    </div>
                    <div className="m_user-profile"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 428.7 468.4" style={{ enableBackground: 'new 0 0 428.7 468.4' }} xmlSpace="preserve">
                        <g>
                            <g>
                                <g>
                                    <path className="user-icon" d="M214.4,3.8c-65.8,0-119.6,53.8-119.6,119.6s53.8,119.6,119.6,119.6S334,189.3,334,123.4S280.2,3.8,214.4,3.8
				z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path className="user-icon" d="M419.7,338.7c-3.1-7.8-7.3-15.2-12-21.9c-24-35.5-61.1-59-102.9-64.8c-5.2-0.5-11,0.5-15.2,3.7
				c-21.9,16.2-48.1,24.6-75.2,24.6s-53.3-8.4-75.2-24.6c-4.2-3.1-9.9-4.7-15.2-3.7c-41.8,5.7-79.4,29.3-102.9,64.8
				c-4.7,6.8-8.9,14.6-12,21.9c-1.6,3.1-1,6.8,0.5,9.9c4.2,7.3,9.4,14.6,14.1,20.9c7.3,9.9,15.2,18.8,24,27.2
				c7.3,7.3,15.7,14.1,24,20.9c41.3,30.8,90.9,47,142.1,47s100.8-16.2,142.1-47c8.4-6.3,16.7-13.6,24-20.9
				c8.4-8.4,16.7-17.2,24-27.2c5.2-6.8,9.9-13.6,14.1-20.9C420.7,345.5,421.2,341.8,419.7,338.7z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    </div>
                </div>
            </>
        );
    }
}
